import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dayName'
})
export class DayNamePipe implements PipeTransform {
	public daysList = [
		{ number: 0, name: "Sunday" },
		{ number: 1, name: "Monday" },
		{ number: 2, name: "Tuesday" },
		{ number: 3, name: "Wednesday" },
		{ number: 4, name: "Thursday" },
		{ number: 5, name: "Friday" },
		{ number: 6, name: "Saturday" },
	]

	transform(value: number, ...args: unknown[]): string {
		return this.daysList.find(d => d.number == value)?.name || "Unknown"
	}

}
