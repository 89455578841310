// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bar {
  width: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/components/loading-screen/loading-screen.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACD;;AAEA;EACC,UAAA;AACD","sourcesContent":[".container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.bar {\n\twidth: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
