import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { User } from "src/app/shared-module/models/user.model";

export class Contact extends CanMarshal<Contact> {
	constructor(propsObj?: Contact) {
		super(propsObj);
		if (propsObj?.userDetails) this.userDetails = new User(propsObj.userDetails);
	}

	uuid?: string
	userId?: string
	userDetails?: User
	name?: string
	email?: string
	phone?: string
	type?: "buddy" | "guide" | "instructor"
	friend?: boolean
	addFriend?: boolean
	avatar?: string
	pendingInvitation?: string
	contactId?: string
	originalName?: string
	signature?: any
}