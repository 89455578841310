import { Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs';
import { LoginService } from '../services/login.service';

@Pipe({
  name: 'depthUnits'
})
export class DepthUnitsPipe implements PipeTransform {

	constructor(private loginService: LoginService) {}

  transform(value: number, ...args: unknown[]): string {
		let userUnits: string
		this.loginService.viewedUser$.pipe(take(1)).subscribe(u => userUnits = u.localeSettings.depthUnits)
		if (userUnits == 'm') {
			return `${value} m`
		} else {
			let f = (value * 0.3048).toFixed(1)
			return `${f} ft`
		}
  }
}
