import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import { Subscription } from 'rxjs';
import { AppBaseNotification } from 'src/app/shared-module/models/app-base-notification.model';
import { User } from 'src/app/shared-module/models/user.model';
import { LoginService } from 'src/app/shared-module/services/login.service';
import { NotificationsService } from 'src/app/shared-module/services/notifications.service';

@Component({
	selector: 'app-notifications-menu',
	templateUrl: './notifications-menu.component.html',
	styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent implements OnInit, OnDestroy {

	constructor(
		private loginService: LoginService,
		private router: Router,
		private notificationsService: NotificationsService,
		private swPush: SwPush
	) { }

	private user = this.loginService.currentUser
	private sub = new Subscription()
	public AppNotifications: AppBaseNotification[] = []
	public newNotifications: number = null
	public notificationsLoaded;

	ngOnInit(): void {
		this.notificationsService.subscribeToNotifications(this.user.uuid)
		this.notificationsService.initAppNotifications()
		this.notificationsService.pushNotifications$.subscribe()
		this.notificationsService.notificationClicks$.subscribe()

		this.sub.add(this.notificationsService.AppNotifications$.subscribe((res: AppBaseNotification[]) => {
			this.notificationsLoaded = res === null ? false : true
			if (res?.length > 0) {
				this.newNotifications = res.filter(notf => !notf.viewedAt).length
				this.AppNotifications = res
			} else {
				this.AppNotifications = null
				this.newNotifications = null
			}
		}))
	}

	onNotificationsOpen() {
		this.newNotifications = null
		if (this.AppNotifications) {
			this.AppNotifications
				.filter(notf => !notf.viewedAt)
				.forEach(notf => this.notificationsService.viewNotification(notf.uuid))
		}
	}

	onNotificationComplete(notf: AppBaseNotification, index: number, completionData: any) {
		this.notificationsService.completeNotification(notf.uuid, completionData).then(res => {
			if (notf.type == "friend-invitation" && res.content.friendContactId) {
				this.router.navigate(['/home/contacts', res.content.friendContactId])
			}
		})
	}

	ngOnDestroy(): void {
		clearInterval(this.notificationsService.notificationsInterval)
		this.sub.unsubscribe()
	}

}
