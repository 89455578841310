import { CanMarshal } from "src/app/shared-module/models/canMarshal.model"
import { AvailableCertificate } from "./available-certificate.model"


export class Association extends CanMarshal<Association> {
	constructor(propsObj?: Association) {
		super(propsObj);
		this.certificates = propsObj.certificates?.map((cer: AvailableCertificate) => new AvailableCertificate(cer));
	}

	public name: string
	public slug?: string
	public certificates?: AvailableCertificate[]
	public uuid: string
}