// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-spinner {
  display: inline-block;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/components/inline-spinner/inline-spinner.component.scss"],"names":[],"mappings":"AAAA;EACC,qBAAA;EACA,iBAAA;AACD","sourcesContent":[".loading-spinner {\n\tdisplay: inline-block;\n\tmargin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
