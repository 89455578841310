import { HttpClient, HttpErrorResponse, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, firstValueFrom, map, tap } from "rxjs";
import { ConfigurationService } from "./configuration.service";
import { User } from "../models/user.model";
import { SilentFail } from "../models/silent-fail.model";


@Injectable({
	providedIn: 'root'
})
export class WebApiService {

	constructor(
		private http: HttpClient,
		private config: ConfigurationService
	) { }

	private authToken: string = undefined;
	private get headers() { return { Authorization: `bearer ${this.authToken}` } }

	public setAuthToken(authToken: string): void {
		this.authToken = authToken;
	}

	public authorize(accessToken: string): Promise<User> {
		return firstValueFrom(this.http.post<any>(
			`${this.config.api}/auth`, { 'access_token': accessToken }
		).pipe(map(res => new User(res)), tap(authResult => this.authToken = authResult.kappasAuthToken)));
	}

	public getOne(path: string, type?: any, caller?: any, params?: {}): Promise<typeof type> {
		this.handleCaller(caller, "GET")
		return firstValueFrom(this.http.get<typeof type>(`${this.config.api}/${path}`, { headers: this.headers, params: params })
			.pipe(
				map(result => type != null ? new type(result) : result)))
	}

	public getList(path: string, type?: any, caller?: any, params?: {}): Promise<typeof type[]> {
		this.handleCaller(caller, "GET")
		return firstValueFrom(this.http.get<typeof type[]>(`${this.config.api}/${path}`, { headers: this.headers, params: params })
			.pipe(
				map(result => result.map(o => type != null ? new type(o) : o))))
	}

	public create(path: string, type: any, data: Partial<typeof type>, caller?: any, params?: {}): Promise<typeof type> {
		this.handleCaller(caller, "POST")
		return firstValueFrom(this.http.post<typeof type>(`${this.config.api}/${path}`, data, { headers: this.headers, params: params })
			.pipe(
				map(result => type != null ? new type(result) : result)))
	}

	public update(path: string, type: any, data: Partial<typeof type>, caller?: any, params?: {}): Promise<typeof type> {
		this.handleCaller(caller, "PATCH")
		return firstValueFrom(this.http.patch<typeof type>(`${this.config.api}/${path}`, data, { headers: this.headers, params: params })
			.pipe(
				map(result => type != null ? new type(result) : result)))
	}

	public delete(path: string, caller?: any, params?: {}): Promise<any> {
		this.handleCaller(caller, "DELETE")
		return firstValueFrom(this.http.delete(`${this.config.api}/${path}`, { headers: this.headers, params: params }))
	}

	public uploadFile(path: string, data: FormData, caller?: any, params?: {}): Observable<any> {
		// this.handleCaller(caller, "POST")
		return this.http.post(`${this.config.api}/${path}`, data, {
			headers: this.headers, params: params, reportProgress: true,
			observe: 'events'
		})
	}

	private loadingLedger: LoadingItem[] = []
	private loadingReportSubject$ = new BehaviorSubject<LoadingItem[]>([])
	public get loadingReport$() {
		return this.loadingReportSubject$.asObservable()
	}

	private progressLedger: LoadingItem[] = []
	private progressReportSubject$ = new BehaviorSubject<LoadingItem[]>([])
	public get progressReport$() {
		return this.progressReportSubject$.asObservable()
	}

	private handleCaller(caller: any | SilentFail, method: "GET" | "POST" | "PATCH" | "DELETE") {
		if (caller) {
			let silentFail = false
			if (caller.silentFail) {
				silentFail = caller.silentFail
				caller = caller.component
			}
			let callerID = Object.entries(caller).find(entry => entry.includes("__ngContext__"))[1] as number
			let loadingItem = { id: callerID, isDone: false, time: new Date().getTime(), method: method, silentFail: silentFail }
			if (method == "GET") {
				this.loadingLedger.push(loadingItem)
			}
			else {
				this.progressLedger.push(loadingItem)
			}
		}
	}

	public onRequest(request: HttpRequest<unknown>) {
		let url =  request.urlWithParams
		if (request.method == "GET" && this.loadingLedger.length > 0 && !this.isExcludedFromLoading(url)) {
			this.loadingLedger[this.loadingLedger.length - 1].url = url
			this.loadingReportSubject$.next(this.loadingLedger)
		}
		if (request.method != "GET" && this.progressLedger.length > 0 && !this.isExcludedFromLoading(url)) {
			this.progressLedger[this.progressLedger.length - 1].url = url
			this.progressReportSubject$.next(this.progressLedger)
		}
	}

	public onResponse(response: HttpResponse<unknown>) {
		let url = response.url
		let loadingItem = this.loadingLedger.find(item => item.url == url)
		if (loadingItem) {
			let index = this.loadingLedger.indexOf(loadingItem)
			if (loadingItem && !this.isExcludedFromLoading(url)) {
				this.loadingLedger[index].isDone = true
				this.loadingReportSubject$.next(this.loadingLedger)
				this.loadingLedger = this.loadingLedger.filter(item => item.id != loadingItem.id)
				this.loadingReportSubject$.next(this.loadingLedger)
			}
		} else {
			loadingItem = this.progressLedger.find(item => item.url == url)
			let index = this.progressLedger.indexOf(loadingItem)
			if (loadingItem && !this.isExcludedFromLoading(url)) {
				this.progressLedger[index].isDone = true
				this.progressReportSubject$.next(this.progressLedger)
				this.progressLedger = this.progressLedger.filter(item => item.id != loadingItem.id)
				this.progressReportSubject$.next(this.progressLedger)
			}
		}
	}

	public onError(err: HttpErrorResponse) {
		let url = err.url
		let loadingItem = this.loadingLedger.find(item => item.url == url)
		if (loadingItem) {
			let index = this.loadingLedger.indexOf(loadingItem)
			if (loadingItem && !this.isExcludedFromLoading(url)) {
				this.loadingLedger[index].error = err.error.message || err.status || "Unknown"
				this.loadingReportSubject$.next(this.loadingLedger)
				this.loadingLedger = this.loadingLedger.filter(item => item.id != loadingItem.id)
				this.loadingReportSubject$.next(this.loadingLedger)
			}
		} else {
			loadingItem = this.progressLedger.find(item => item.url == err.url)
			let index = this.progressLedger.indexOf(loadingItem)
			if (loadingItem && !this.isExcludedFromLoading(url)) {
				this.progressLedger[index].error = err.error.message || err.status || "Unknown"
				this.progressReportSubject$.next(this.progressLedger)
				this.progressLedger = this.progressLedger.filter(item => item.id != loadingItem.id)
				this.progressReportSubject$.next(this.progressLedger)
			}
		}
	}

	private loadingExludedList: Set<string> = new Set()

	public excludeFromLoading(path: string) {
		this.loadingExludedList.add(path)
	}

	private isExcludedFromLoading(url: string): boolean {
		for (let path of this.loadingExludedList) {
			if (url == `${this.config.api}/${path}`) {
				return true
			}
		}
		return false
	}
}


interface LoadingItem {
	id?: number,
	url?: string,
	error?: string,
	method: "GET" | "POST" | "PATCH" | "DELETE"
	time: number,
	isDone: boolean,
	silentFail: boolean
}
