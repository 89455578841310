import { NgModule, APP_INITIALIZER, LOCALE_ID, isDevMode } from "@angular/core";
import { ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ShowOnDirtyErrorStateMatcher } from "@angular/material/core";
import { OAuthModule } from "angular-oauth2-oidc";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { ConfigurationService } from "./shared-module/services/configuration.service";
import { SharedModule } from "./shared-module/shared.module";
import { LibrariesModule } from "./libraries-module/libraries.module";
import { LastDiveComponent } from "./components/last-dive/last-dive.component";

import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { AdminPageComponent } from "./admin-module/pages/admin-page/admin-page.component";
import { AnonymousPageComponent } from "./pages/anonymous-page/anonymous-page.component";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { AboutHomeComponent } from "./pages/about-page/about-page.component";
import { DevComponent } from "./pages/dev/dev.component"; // tests
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular-material-components/moment-adapter";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ProgressInterceptor } from "./shared-module/interceptors/progress.interceptor";
import { CustomErrorStateMatcher } from "./shared-module/services/custom-error-state-matcher";
import { ServiceWorkerModule } from '@angular/service-worker';
import { NotificationsPageComponent } from './pages/notifications-page/notifications-page.component';
import { NotificationsHandlerComponent } from './components/notifications-handler/notifications-handler.component';
import { ApproveDiveHandlerComponent } from './components/approve-dive-handler/approve-dive-handler.component';
import { NotificationsMenuComponent } from './components/notifications-menu/notifications-menu.component';
import { InvitationPageComponent } from './pages/invitation-page/invitation-page.component';
import { FriendInvitationHandlerComponent } from './components/friend-invitation-handler/friend-invitation-handler.component';


@NgModule({
	declarations: [
		AppComponent,
		ContactPageComponent,
		AdminPageComponent,
		AnonymousPageComponent,
		MainPageComponent,
		LastDiveComponent,
		DevComponent,
		AboutHomeComponent,
		NotificationsPageComponent,
		NotificationsHandlerComponent,
		ApproveDiveHandlerComponent,
		NotificationsMenuComponent,
		InvitationPageComponent,
		FriendInvitationHandlerComponent,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		LibrariesModule,
		AppRoutingModule,
		SharedModule,
		OAuthModule.forRoot(),
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: true,
			registrationStrategy: 'registerImmediately'
		})
	],
	providers: [
		ConfigurationService,
		{
			provide: APP_INITIALIZER, useFactory: (c: ConfigurationService) => c.factory,
			deps: [ConfigurationService], multi: true,
		},
		// TODO: select locale from user's browser, have a "settings" page
		{ provide: LOCALE_ID, useValue: 'en-IL' },
		{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: { showDelay: 500 } },
		{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } },
		{ provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
		{ provide: ErrorStateMatcher, useClass: CustomErrorStateMatcher }
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule { }


