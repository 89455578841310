import { Injectable } from "@angular/core";
import { Dive } from "../models/dive.model";
import { WebApiService } from "src/app/shared-module/services/web-api.service";
import { DiveValidation } from "../models/dive-validation.model";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { Moment } from "moment";
import { LoginService } from "src/app/shared-module/services/login.service";

@Injectable({
	providedIn: 'root',
})
export class DivesService {

	constructor(
		private webApi: WebApiService,
		private loginService: LoginService) { }

	private get user() {
		return this.loginService.viewedUser.uuid
	}

	public listDives(start?: number, end?: number, caller?: any): Promise<Dive[]> {
		let params = start && end ? { start: start, end: end } : null
		return this.webApi.getList(`users/${this.user}/dives`, Dive, caller, params)
	}

	public getDive(uuid: string, caller?: any): Promise<Dive> {
		return this.webApi.getOne(`users/${this.user}/dives/${uuid}`, Dive, caller)
	}

	public createDive(data: Partial<Dive>, caller?: any): Promise<Dive> {
		return this.webApi.create(`users/${this.user}/dives`, Dive, data, caller)
	}

	public updateDive(uuid: string, data: Partial<Dive>, caller?: any): Promise<Dive> {
		return this.webApi.update(`users/${this.user}/dives/${uuid}`, Dive, data, caller)
	}

	public deleteDive(uuid: string, caller?: any) {
		return this.webApi.delete(`users/${this.user}/dives/${uuid}`, caller)
	}

	public getNextDiveNo(caller?: any): Promise<DiveValidation> {
		this.webApi.excludeFromLoading(`users/${this.user}/dives/validate`)
		return this.webApi.getOne(`users/${this.user}/dives/validate`, DiveValidation, caller)
			.then((res: DiveValidation) => {
				this.minStartTimeStampSubject$.next(res.startTimestamp)
				this.maxEndTimeStampSubject$.next(res.endTimestamp)
				return res
			});
	}

	public validateDiveNo(control: AbstractControl): Promise<ValidationErrors | null> {
		this.webApi.excludeFromLoading(`users/${this.user}/dives/validate`)
		let validationData: DiveValidation = { diveNo: control.value }
		return this.webApi.create(`users/${this.user}/dives/validate`, DiveValidation, validationData)
			.then(
				(res => {
					this.minStartTimeStampSubject$.next(res.startTimestamp);
					this.maxEndTimeStampSubject$.next(res.endTimestamp);
					return null
				}),
				(res => { return { diveNoTaken: true } }))
	}

	private minStartTimeStampSubject$ = new BehaviorSubject<Moment>(null)
	public get minStartTimeStamp$() {
		return this.minStartTimeStampSubject$.asObservable()
	}
	private maxEndTimeStampSubject$ = new BehaviorSubject<Moment>(null)
	public get maxEndTimeStamp$() {
		return this.maxEndTimeStampSubject$.asObservable()
	}

}


