import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageItem } from 'src/app/home-module/models/image-item.model';

@Component({
	selector: 'app-images-form',
	templateUrl: './images-form.component.html',
	styleUrls: ['./images-form.component.scss']
})
export class ImagesFormComponent {

	@Input() imageList: ImageItem[];
	@Output() imageListChanged = new EventEmitter<ImageItem[]>();

	addImage(image: ImageItem) {
		if (!this.imageList) {
			this.imageList = []
		}
		this.imageList.push(image)
		this.imageListChanged.emit(this.imageList)
	}

	deleteImage(index: number) {
		this.imageList.splice(index, 1)
		this.imageListChanged.emit(this.imageList)
	}

}
