import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { BehaviorSubject, Observable, firstValueFrom, tap } from "rxjs";
import { auth0config } from "../auth/auth.auth0.config";
import { LoginErrorMessageDialog } from "../components/login-error-message-dialog/login-error-message-dialog";
import { ConfigurationService } from "./configuration.service";
import { WebApiService } from "./web-api.service";
import { User } from "../models/user.model";

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	constructor(
		private oauthService: OAuthService,
		private dialog: MatDialog,
		private config: ConfigurationService,
		private webApi: WebApiService) { }

	private _user: User | null;
	private get user() { return this._user }
	private set user(u: User) {
		this._user = u;
		this.currentUserSubject$.next(u);
		this.viewedUsersubject$.next(u)
	}

	public get currentUser(): User | null {
		return this.user
	}

	private currentUserSubject$ = new BehaviorSubject<User>(null)
	public get currentUser$() {
		return this.currentUserSubject$.asObservable()
	}

	public refreshCurrentUser(userData: User) {
		if (userData.uuid == this.currentUser.uuid) {
			this.user = new User(userData)
		} else {
			this.viewedUsersubject$.next(userData)
		}
	}

	private viewedUsersubject$ = new BehaviorSubject<User>(null)
	public get viewedUser$() {
		return this.viewedUsersubject$.asObservable()
	}
	public get viewedUser() {
		return this.viewedUsersubject$.getValue()
	}

	public emulateUser(user: User) {
		this.viewedUsersubject$.next(user)
	}

	public stopEmulation() {
		this.viewedUsersubject$.next(this.currentUser)
	}

	private identity: { [key: string]: any } | null;

	private isLoggenInSubject = new BehaviorSubject<boolean>(false)
	public get isLoggedIn$(): Observable<boolean> {
		return this.isLoggenInSubject.asObservable()
	}

	private isLoadingSubject = new BehaviorSubject<boolean>(false)
	public get isLoading$(): Observable<boolean> {
		return this.isLoadingSubject.asObservable()
	}

	public initLoginService() {
		this.isLoadingSubject.next(true)
		this.load();
		this.oauthService.configure(this.config.updateAuthConfig(auth0config));
		this.oauthService.setupAutomaticSilentRefresh();
		this.oauthService.tokenValidationHandler = new JwksValidationHandler();
		this.oauthService.loadDiscoveryDocumentAndTryLogin()
			.then(ok => {
				if (ok) {
					this.identity = this.oauthService.getIdentityClaims();
					localStorage.setItem('claimed-identity', JSON.stringify(this.identity));
					let token = this.oauthService.getAccessToken();
					console.log("LoginService: ok", ok, this.identity, token);
					if (!token) { this.isLoadingSubject.next(false); return }
					this.webApi.authorize(token)
						.then(res => this.authDone(res))
						.catch(err => {
							console.warn("Authorization error", err);
							this.loginErrorMessage(`Unauthorized: ${err.message}`).then(v => {
								this.isLoadingSubject.next(false)
							});
						});
				} else {
					if (this.user) {
						this.webApi.setAuthToken(this.user.kappasAuthToken);
						this.webApi.getOne("users/self", User)
							.then(res => this.authDone(res))
							.catch(err => {
								console.warn("Authorization error", err);
								this.loginErrorMessage(`Unauthorized: ${err.message}`).then(v => {
									this.isLoadingSubject.next(false)
								});
							});
					} else {
						this.isLoadingSubject.next(false);
					};
				};
			});
	}

	private authDone(result: User): void {
		console.log('Logged in user', result.displayName, result.email);
		this.user = new User(result);
		localStorage.setItem('user-account', JSON.stringify(this.user));
		this.isLoggenInSubject.next(true);
		this.isLoadingSubject.next(false);
	}

	public logIn(params?: { [key: string]: any }) {
		this.oauthService.initLoginFlow(JSON.stringify(params));
	}

	public logOut() {
		localStorage.removeItem('user-account');
		this.user = null;
		this.oauthService.logOut();
		this.isLoggenInSubject.next(false)
	}

	private load() {
		if (localStorage.getItem('user-account')) {
			this.user = new User(JSON.parse(localStorage.getItem('user-account'))) || null;
		}
		this.identity = JSON.parse(localStorage.getItem('claimed-identity'));
	}

	public loginErrorMessage(msg: string): Promise<any> {
		const dialogRef = this.dialog.open(LoginErrorMessageDialog, {
			data: msg
		});
		return firstValueFrom(dialogRef.afterClosed());
	}




}
