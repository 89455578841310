import { CanMarshal } from "src/app/shared-module/models/canMarshal.model"
import { EquipmentType } from "./equipment-type.model"
import { CalendarDate } from "calendar-date";


export class EquipmentItem extends CanMarshal<EquipmentItem> {

	constructor(propsObj?: EquipmentItem) {
		super(propsObj);
		this.defaultEquipment = propsObj.defaultEquipment || false
		this.nextMaintenance = propsObj.nextMaintenance ? new CalendarDate(String(propsObj.nextMaintenance)) : null
	}

	public uuid: string
	public type: EquipmentType
	public manufacturer: string
	public model: string
	public nextMaintenance: CalendarDate
	public name: string
	public defaultEquipment: boolean


}