import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { User } from "src/app/shared-module/models/user.model";

export class ImageItem extends CanMarshal<ImageItem> {
	uuid?: string
	filename?: string
	url: string
	contentType?: string
	title?: string
	purpose?: string
	votesUp?: number
	votesDown?: number
		// uploader?: User
}