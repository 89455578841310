import { Association } from "./association.model";
import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { CertificateType } from "./certificate-type.model";
import { CalendarDate } from "calendar-date";

export class Certification extends CanMarshal<Certification> {
	constructor(propsObj?: Certification) {
		super(propsObj)
		this.certifiedAt = new CalendarDate(String(propsObj.certifiedAt))
	}

	public certificate: string
	public uuid: string
	public certifiedAt: CalendarDate
	public certificationId: string
	public description: string
	public association: string
	public instructor: string
	public resort?: string
	public certification: string
	public type: CertificateType
}