import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-loading-screen',
	templateUrl: './loading-screen.component.html',
	styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {
	@Input() shape: string = "bar"
	@Input() height: string = "auto"
	@Input() error: string = null
	public style = {
		height: "50vh"
	}

	ngOnInit(): void {
		if (this.height != "auto") this.style.height = this.height + "px"
	}

}
