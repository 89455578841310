import { Injectable } from "@angular/core"
import { WebApiService } from "src/app/shared-module/services/web-api.service"
import { EquipmentItem } from "../models/equipment-item.model"
import { CalendarDate } from "calendar-date"
import { LoginService } from "src/app/shared-module/services/login.service"


@Injectable({
	providedIn: 'root',
})
export class EquipmentService {

	constructor(
		private webApi: WebApiService,
		private loginService: LoginService) { }

	private get user() {
		return this.loginService.viewedUser.uuid
	}


	public listEquipment(caller?: any): Promise<EquipmentItem[]> {
		return this.webApi.getList(`users/${this.user}/equipment`, EquipmentItem, caller)
	}

	public getEquipment(uuid: string, caller?: any): Promise<EquipmentItem> {
		return this.webApi.getOne(`users/${this.user}/equipment/${uuid}`, EquipmentItem, caller)
	}

	public createEquipment(data: Partial<EquipmentItem>, caller?: any): Promise<EquipmentItem> {
		return this.webApi.create(`users/${this.user}/equipment`, EquipmentItem, data, caller)
	}

	public updateEquipment(uuid: string, data: Partial<EquipmentItem>, caller?: any): any {
		return this.webApi.update(`users/${this.user}/equipment/${uuid}`, EquipmentItem, data, caller)
	}

	public deleteEquipment(uuid: string, caller?: any): any {
		return this.webApi.delete(`users/${this.user}/equipment/${uuid}`, caller)
	}

	public listDefault(caller?: any): Promise<EquipmentItem[]> {
		return this.webApi.getList(`users/${this.user}/equipment`, EquipmentItem, { "default": "" }, caller)
	}

	public maintenanceCheck(nextMaintenance: CalendarDate): null | 7 | 30 {
		const diff = nextMaintenance.getDifferenceInDays(CalendarDate.nowLocal())
		if (diff <= 7) {
			return 7
		} else if (diff <= 30) {
			return 30
		} else {
			return null
		}
	}

}




