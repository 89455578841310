import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
	selector: 'login-error-message-dialog',
	templateUrl: 'login-error-message-dialog.html',
	styleUrls: ['login-error-message-dialog.scss']
})
export class LoginErrorMessageDialog {

	constructor(public dialogRef: MatDialogRef<LoginErrorMessageDialog>, @Inject(MAT_DIALOG_DATA) public data: string) {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

}
