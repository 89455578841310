import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthConfig } from 'angular-oauth2-oidc';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable, Subject, timer, NEVER } from 'rxjs';
import { AppConfig } from '../models/app-config.model';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationService {
	private readonly configURL: string = '/appconfig.json';
	private configData: AppConfig | undefined;
	private _updates: Subject<void> = new Subject();

	constructor(private http: HttpClient) { }

	loadConfiguration(): Promise<AppConfig> {
		return this.http
			.get<AppConfig>(this.configURL + '?' + Math.floor(Math.random() * 10000))
			.pipe(
				map(config => this.configData = config),
				catchError((err: HttpErrorResponse) => {
					let msg = `Error loading configuration from ${err.url} (${err.status}): ` + JSON.stringify(err.error);
					if (this.configData) { // this is a reload error - we can ignore temporary network issues after we load
						console.warn(msg);
						return NEVER;
					}
					return throwError(msg);
				})).toPromise();
	}

	get config(): AppConfig {
		return this.configData!;
	}

	get api(): string {
		return this.configData!.api
	}

	get updates(): Observable<void> {
		let version = this.configData?.version;
		timer(300000, 300000).subscribe(_ => this.loadConfiguration().then(c => {
			if (version && version.localeCompare(c.version, undefined, { numeric: true }) >= 0)
				return;
			version = c.version;
			this._updates.next();
		}));
		return this._updates.asObservable();
	}

	get factory(): () => Promise<any> {
		return () => this.loadConfiguration();
	}

	updateAuthConfig(config: AuthConfig): AuthConfig {
		let temp = { ...config };
		temp.clientId = this.configData?.OAuthClientId;
		temp.logoutUrl = temp.logoutUrl?.replace(/&client_id=[^&]+/, `&client_id=${temp.clientId}`);
		return temp;
	}
}
