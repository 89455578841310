import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FriendInvitationNotification } from 'src/app/shared-module/models/friend-invitation-notification.model';
import { LoadingHost } from 'src/app/shared-module/models/loading-host.model';
import { LoginService } from 'src/app/shared-module/services/login.service';
import { NotificationsService } from 'src/app/shared-module/services/notifications.service';

@Component({
	selector: 'app-friend-invitation-handler',
	templateUrl: './friend-invitation-handler.component.html',
	styleUrls: ['./friend-invitation-handler.component.scss'],
	providers: [{provide: LoadingHost, useExisting: FriendInvitationHandlerComponent}]
})
export class FriendInvitationHandlerComponent {
	constructor(
		private notificationsService: NotificationsService,
		private router: Router,
		private loginService: LoginService
	) { }

	@Input() notification: FriendInvitationNotification;
	private user = this.loginService.currentUser

	onSubmit(response: string) {
		let completionData = { response: response, token: this.notification.content.token }
		this.notificationsService.completeNotification(this.notification.uuid, completionData, this)
	}

}
