// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainbox {
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.phone {
  margin: 0 auto;
  border: solid black 7px;
  border-radius: 15px;
  width: 200px;
  height: 400px;
  padding: 0;
}
.phone .main-image {
  background-image: url(/assets/images/vertical-background.jpeg);
  background-size: contain;
  height: 340px;
  background-repeat: no-repeat;
  margin: 0 auto;
}

.image {
  flex-grow: 1;
  padding: 0;
}
.image .main-image {
  margin: 0 auto;
  background-image: url(/assets/images/vertical-background.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 450px;
  max-height: 720px;
  width: calc(100vw - 20em);
  height: calc((100vw - 20em) / 450 * 720);
}

.signup {
  flex-basis: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/anonymous-page/anonymous-page.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;AACF;;AAEA;EACE,cAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,UAAA;AACF;AAAE;EACE,8DAAA;EACA,wBAAA;EACA,aAAA;EACA,4BAAA;EACA,cAAA;AAEJ;;AAEA;EACC,YAAA;EACA,UAAA;AACD;AAAC;EACC,cAAA;EACA,8DAAA;EACA,sBAAA;EACA,4BAAA;EACA,gBAAA;EACE,iBAAA;EACA,yBAAA;EACF,wCAAA;AAEF;;AAEA;EACC,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACD","sourcesContent":[".mainbox {\n  padding: 1em;\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n}\n\n.phone {\n  margin: 0 auto;\n  border: solid black 7px;\n  border-radius: 15px;\n  width: 200px;\n  height: 400px;\n  padding: 0;\n  .main-image {\n    background-image: url(/assets/images/vertical-background.jpeg) ;\n    background-size: contain;\n    height: 340px;\n    background-repeat: no-repeat;\n    margin: 0 auto;\n  }\n}\n\n.image {\n\tflex-grow: 1;\n\tpadding: 0;\n\t.main-image {\n\t\tmargin: 0 auto;\n\t\tbackground-image: url(/assets/images/vertical-background.jpeg) ;\n\t\tbackground-size: cover;\n\t\tbackground-repeat: no-repeat;\n\t\tmax-width: 450px;\n\t  \tmax-height: 720px;\n\t  \twidth: calc(100vw - 20em);\n\t\theight: calc((100vw - 20em)/450*720);\n\t}\n}\n\n.signup{\n\tflex-basis: 20em;\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
