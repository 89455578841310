import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { LoginService } from "./shared-module/services/login.service";
import { User } from "./shared-module/models/user.model";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	title = 'Kappas';

	constructor(
		private loginService: LoginService,
		private router: Router,
	) { }

	public isLoggedIn$!: Observable<boolean>;
	public isLoading$!: Observable<boolean>;
	public viewedUser$!: Observable<User>
	private sub = new Subscription()

	ngOnInit(): void {
		this.isLoggedIn$ = this.loginService.isLoggedIn$;
		this.isLoading$ = this.loginService.isLoading$
		this.viewedUser$ = this.loginService.viewedUser$
		this.loginService.initLoginService();
	}

	public login(): void {
		this.loginService.logIn();
	}

	public logout(): void {
		this.loginService.logOut();
		this.router.navigate(['/']);
	}

	public get user(): User | null {
		return this.loginService.currentUser;
	}

	public stopEmulation() {
		this.loginService.stopEmulation()
		this.router.navigate(['admin'])
	}

	ngOnDestroy(): void { }
}
