import { Pipe, PipeTransform } from '@angular/core';
import { LoginService } from '../services/login.service';
import { take } from 'rxjs';

@Pipe({
  name: 'tempUnits'
})
export class TempUnitsPipe implements PipeTransform {

	constructor(private loginService: LoginService) {}

  transform(value: number, ...args: unknown[]): string {
		let userUnits: string
		this.loginService.viewedUser$.pipe(take(1)).subscribe(u => userUnits = u.localeSettings.tempUnits)
		if (userUnits == 'c') {
			return `${value} °C`
		} else {
			let f = ((value - 32) / 1.8).toFixed(1)
			return `${f} °F`
		}
  }

}
