// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-a-link {
  color: inherit;
  text-decoration: none;
}

a {
  font-size: 40px;
  padding-bottom: 25px;
}

.mat-toolbar-row {
  display: flex;
  background-color: #b3f0ff;
  vertical-align: text-bottom;
}

.mat-toolbar-row .fx-spacer {
  flex: 1 1 auto;
}

.bottom-row {
  background-color: #000080;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.bottom-row a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.bottom-row a:hover {
  background-color: #008080;
  color: black;
}

/* Add a color to the active/current link */
.bottom-row a.active {
  background-color: #4CAF50;
  color: white;
}

.avatar {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.emulation-warning {
  padding: 5px;
  background-color: rgb(203, 0, 0);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.emulation-warning div {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,qBAAA;AACD;;AAEA;EACC,eAAA;EACA,oBAAA;AACD;;AAGA;EACC,aAAA;EACA,yBAAA;EACA,2BAAA;AAAD;;AAGA;EACC,cAAA;AAAD;;AAGA;EACC,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;AAAD;;AAGA;EACC,WAAA;EACA,cAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;AAAD;;AAGA,uCAAA;AACA;EACC,yBAAA;EACA,YAAA;AAAD;;AAGA,2CAAA;AACA;EACC,yBAAA;EACA,YAAA;AAAD;;AAGA;EACC,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AAAD;;AAGA;EACC,YAAA;EACA,gCAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAD;AACC;EACC,kBAAA;AACF","sourcesContent":[".not-a-link {\n\tcolor: inherit;\n\ttext-decoration: none;\n}\n\na {\n\tfont-size: 40px;\n\tpadding-bottom: 25px;\n\n}\n\n.mat-toolbar-row {\n\tdisplay: flex;\n\tbackground-color: #b3f0ff;\n\tvertical-align: text-bottom;\n}\n\n.mat-toolbar-row .fx-spacer {\n\tflex: 1 1 auto;\n}\n\n.bottom-row {\n\tbackground-color: #000080;\n\toverflow: hidden;\n\tposition: fixed;\n\tbottom: 0;\n\twidth: 100%;\n}\n\n.bottom-row a {\n\tfloat: left;\n\tdisplay: block;\n\tcolor: #f2f2f2;\n\ttext-align: center;\n\tpadding: 14px 16px;\n\ttext-decoration: none;\n\tfont-size: 17px;\n}\n\n/* Change the color of links on hover */\n.bottom-row a:hover {\n\tbackground-color: #008080;\n\tcolor: black;\n}\n\n/* Add a color to the active/current link */\n.bottom-row a.active {\n\tbackground-color: #4CAF50;\n\tcolor: white;\n}\n\n.avatar {\n\twidth: 50px;\n\theight: 50px;\n\tobject-fit: cover;\n\tborder-radius: 50%;\n\tcursor: pointer;\n}\n\n.emulation-warning {\n\tpadding: 5px;\n\tbackground-color: rgb(203, 0, 0);\n\tcolor: white;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tdiv {\n\t\tmargin-right: 10px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
