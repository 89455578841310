// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.avatar-parent {
  height: auto;
  width: auto;
}

.notifications-menu {
  max-width: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/notifications-menu/notifications-menu.component.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AACD;;AAEA;EACC,YAAA;EACA,WAAA;AACD;;AAEA;EACC,0BAAA;AACD","sourcesContent":[".avatar {\n\twidth: 40px;\n\theight: 40px;\n\tobject-fit: cover;\n\tborder-radius: 50%;\n}\n\n.avatar-parent {\n\theight: auto;\n\twidth: auto;\n}\n\n.notifications-menu {\n\tmax-width: none !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
