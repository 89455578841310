import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { EmergencyContact } from "./emergency-contact.model";
import { Role } from "./role.model";
import { RoleType } from "./role-type.model";
import { AuthToken } from "./auth-token.model";
import { UserAffiliation } from "src/app/home-module/models/user-affiliation.model";
import { Moment } from "moment";
import { CalendarDate } from "calendar-date";
import { LocaleSettings } from "./locale-settings.model";
import { ImageItem } from "src/app/home-module/models/image-item.model";

export class User extends CanMarshal<User> {

	constructor(propsObj?: User) {
		super(propsObj);
		this.roles = propsObj.roles?.map((role: Role) => new Role(role));
		this.localeSettings = new LocaleSettings(propsObj.localeSettings || { language: "en", tempUnits: "c", depthUnits: "m" } as LocaleSettings);
		if (propsObj.emergencyContact) this.emergencyContact = new EmergencyContact(propsObj.emergencyContact);
		if (propsObj.authTokens) this.authTokens = propsObj.authTokens.map((token: AuthToken) => new AuthToken(token));
		if (propsObj.dateOfBirth) this.dateOfBirth = new CalendarDate(String(propsObj.dateOfBirth));
	}
	
	public uuid: string
	public name: string
	public displayName: string
	public email: string
	public localeSettings: LocaleSettings
	public phone: string
	public roles: Role[]
	public blocked: boolean
	public dateOfBirth?: CalendarDate
	public emergencyContact?: EmergencyContact
	public authTokens?: AuthToken[]
	public affiliations?: UserAffiliation[]
	public avatar?: string | ImageItem
	public unregisteredBuddies?: string[]
	public signingKeys?: any[]
	public diveCount?: number

	private hasRole(role: RoleType): boolean {
		for (let r of this.roles)
			if (r.type === role)
				return true;
		return false;
	}

	public get isAdmin(): boolean {
		return this.hasRole("systemManager");
	}

	// Has access to the admin panel - basically anybody other than a diver
	public get isManager(): boolean {
		return this.hasRole("systemManager") ||
			this.hasRole("associationManager") ||
			this.hasRole("resortManager") ||
			this.hasRole("resortRep") ||
			this.hasRole("resortInstructor");
	}

	public get kappasAuthToken(): string | null {
		return this.authTokens?.find(tok => tok.provider == 'kappas')?.token;
	}

	public get tempSymbol(): string {
		switch (this.localeSettings.tempUnits) {
			case "c": return "°C";
			case "f": return "°F";
		}
	}

	public get tempName(): string {
		switch (this.localeSettings.tempUnits) {
			case "c": return "Celsius";
			case "f": return "Fahrenheit";
		}
	}

	public get depthSymbol(): string {
		switch (this.localeSettings.depthUnits) {
			case "m": return "m";
			case "f": return "ft";
		}
	}

	public get depthName(): string {
		switch (this.localeSettings.depthUnits) {
			case "m": return "Meters";
			case "f": return "Feet";
		}
	}

	public get tempUnits(): string {
		return this.localeSettings.tempUnits
	}

	public get depthUnits(): string {
		return this.localeSettings.depthUnits
	}
}