import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-progress-screen',
	templateUrl: './progress-screen.component.html',
	styleUrls: ['./progress-screen.component.scss']
})
export class ProgressScreenComponent {
	@Input() shape: string = "spinner"
	@Input() error: string = null

	@Output() tryAgain = new EventEmitter<boolean>()

	ngOnInit(): void { }

	onTryAgain() {
		this.tryAgain.emit(true)
	}

}
