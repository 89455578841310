import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoginService } from "src/app/shared-module/services/login.service";
import { WebApiService } from "src/app/shared-module/services/web-api.service";
import { Role } from "../models/role.model";
import { User } from "../models/user.model";
import { EmergencyContact } from "../models/emergency-contact.model";


@Injectable({
	providedIn: 'root',
})
export class UserService {

	constructor(
		private webApi: WebApiService,
		private loginService: LoginService,
		private snacks: MatSnackBar) { }

	public get currentUser(): User {
		return this.loginService.currentUser;
	}

	private get viewedUser() {
		return this.loginService.viewedUser.uuid
	}

	public listUsers(caller?: any): Promise<User[]> {
		return this.webApi.getList("users", User, caller)
	}

	public getUser(uuid: string, caller?: any): Promise<User> {
		return this.webApi.getOne(`users/${uuid}`, User, caller)
	}

	public blockUser(user: User, caller?: any): Promise<User> {
		if (user.uuid == this.currentUser.uuid) {
			this.snacks.open("You are not allowed to block yourself!", "OK");
			return;
		}
		return this.webApi.update(`users/${user.uuid}`, User, { 'blocked': true }, caller)
	}

	public unBlockUser(user: User, caller?: any): Promise<User> {
		return this.webApi.update(`users/${user.uuid}`, User, { 'blocked': false }, caller)
	}

	public updateUser(data: Partial<User>, caller?: any): Promise<User> {
		return this.webApi.update(`users/${this.viewedUser}`, User, data, caller)
	}

	// needs work
	public makeAdmin(user: User, caller?: any): Promise<User> {
		let managerRole = { role: 0, type: "systemManager" } as Role
		return this.webApi.update(`users/${user.uuid}`, User, { 'roles': [managerRole] }, caller)
	}

	public deleteUser(user: User, caller?: any): Promise<void> {
		return this.webApi.delete(`users/${user.uuid}`, caller);
	}

}
