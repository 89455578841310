import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AboutHomeComponent } from "./pages/about-page/about-page.component";
import { ContactPageComponent } from "./pages/contact-page/contact-page.component";
import { AdminPageComponent } from "./admin-module/pages/admin-page/admin-page.component";
import { AnonymousPageComponent } from "./pages/anonymous-page/anonymous-page.component";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { DevComponent } from "./pages/dev/dev.component";
import { AuthGuardService } from "./shared-module/services/auth-guard.service";
import { NotificationsPageComponent } from "./pages/notifications-page/notifications-page.component";
import { InvitationPageComponent } from "./pages/invitation-page/invitation-page.component";


const routes: Routes = [
	{ path: '', component: AnonymousPageComponent },
	{ path: 'main', component: MainPageComponent, canActivate: [AuthGuardService] },
	{ path: 'about', component: AboutHomeComponent },
	{ path: 'contact', component: ContactPageComponent },
	{ path: 'admin', loadChildren: () => import('./admin-module/admin.module').then(i => i.AdminModule), canActivate: [AuthGuardService] },
	{ path: 'home', loadChildren: () => import('./home-module/home.module').then(i => i.HomeModule), canActivate: [AuthGuardService] },
	{ path: 'locations', loadChildren: () => import('./locations-module/locations.module').then(i => i.LocationsModule), canActivate: [AuthGuardService] },
	{ path: 'notifications/:uuid', component: NotificationsPageComponent, canActivate: [AuthGuardService] },
	{ path: 'invitation/:uuid', component: InvitationPageComponent },
	{ path: 'dev', component: DevComponent }, // hide for deployment
	{ path: '**', redirectTo: 'main' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
	providers: [AuthGuardService]
})
export class AppRoutingModule { }
