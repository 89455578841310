import { AuthConfig} from "angular-oauth2-oidc" ;

export const auth0config: AuthConfig = {
    issuer: "https://kappas.auth0.com/",
    redirectUri: window.location.origin,
    strictDiscoveryDocumentValidation: false,
    scope: "openid profile email",
    // logoutUrl: 'https://kappas.auth0.com/v2/logout?' +
	// 		`federated&client_id=${clientId}` +
	// 		'&returnTo=' + encodeURIComponent(window.location.origin),
};
