// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-input {
  display: none;
}

.progress {
  padding: 10px;
  width: 50vw;
}

.form-container button {
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/components/file-upload/file-upload.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;AACD;;AAEA;EACC,aAAA;EACA,WAAA;AACD;;AAGC;EACC,YAAA;AAAF","sourcesContent":[".file-input {\n\tdisplay: none;\n}\n\n.progress {\n\tpadding: 10px;\n\twidth: 50vw;\n}\n\n.form-container {\n\tbutton {\n\t\tmargin: 10px;\n\t}\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
