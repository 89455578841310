import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppBaseNotification } from 'src/app/shared-module/models/app-base-notification.model';
import { LoadingHost } from 'src/app/shared-module/models/loading-host.model';
import { LoginService } from 'src/app/shared-module/services/login.service';
import { NotificationsService } from 'src/app/shared-module/services/notifications.service';

@Component({
	selector: 'app-notifications-handler',
	templateUrl: './notifications-handler.component.html',
	styleUrls: ['./notifications-handler.component.scss'],
	providers: [{ provide: LoadingHost, useExisting: NotificationsHandlerComponent }]
})
export class NotificationsHandlerComponent implements OnInit, OnDestroy {

	constructor(
		private activatedRoute: ActivatedRoute,
		private loginService: LoginService,
		private notificationsService: NotificationsService,
		private router: Router) { }

	private sub = new Subscription()
	public user = this.loginService.currentUser
	public uuid: string;
	public notification: AppBaseNotification = null;

	ngOnInit(): void {
		this.sub.add(this.activatedRoute.params.subscribe(params => {
			if (params["uuid"]) {
				this.uuid = params["uuid"]
				this.notificationsService.getNotification(this.uuid, this)
					.catch(err => this.router.navigate(['/']))
					.then((notification: AppBaseNotification) => {
						this.notification = new AppBaseNotification(notification)
						if (this.notification.type == 'friend-approval') [
							this.router.navigate(['home/contacts', notification.content.contactId])
						]
					})
			} else {
				this.router.navigate(['/'])
			}
		})
		)
	}

	ngOnDestroy(): void {

	}
}
