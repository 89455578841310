import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { LoginService } from './login.service';
import { Observable, filter, firstValueFrom, lastValueFrom, take } from 'rxjs';


@Injectable()
export class AuthGuardService  {

	constructor(
		private loginService: LoginService,
		private router: Router) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
		Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

		let path = route.url.map(e => e.path).join("/");

		const result = lastValueFrom(this.loginService.isLoading$.pipe(filter(isLoading => !isLoading), take(1)))
			.then(doneLoading => {
				return firstValueFrom(this.loginService.isLoggedIn$)
					.then(isLoggedIn => {
						if (isLoggedIn) {
							if (path.startsWith("admin") && !this.loginService.currentUser.isManager) return false;
							return true;
						}
						return this.router.parseUrl('/')
					})
			})
		return result
	}
}