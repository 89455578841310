import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { LoginService } from "src/app/shared-module/services/login.service";


@Component({
	selector: 'app-home-anonymous',
	templateUrl: './anonymous-page.component.html',
	styleUrls: ['./anonymous-page.component.scss']
})
export class AnonymousPageComponent implements OnInit, OnDestroy {
	constructor(
		private loginService: LoginService,
		private router: Router) {
	}

	private subscriptions = new Subscription();

	public login(): void {
		this.loginService.logIn();
	}

	ngOnInit() {
		this.subscriptions.add(this.loginService.isLoggedIn$.subscribe(
			isLoggedIn => { (isLoggedIn) ? this.router.navigate(['/main']) : null }))
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
