// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.images-container {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}

.image-container {
  width: min-content;
  position: relative;
  margin-right: 20px;
}
.image-container button {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 1000;
}

.dive-image {
  border-radius: 5px;
  height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared-module/components/images-form/images-form.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,eAAA;EACA,kBAAA;AACD;;AAEA;EACC,kBAAA;EACA,kBAAA;EACA,kBAAA;AACD;AACC;EACC,kBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;AACF;;AAGA;EACC,kBAAA;EACA,aAAA;AAAD","sourcesContent":[".images-container {\n\tdisplay: flex;\n\tmargin-top: 5px;\n\tmargin-bottom: 5px;\n}\n\n.image-container {\n\twidth: min-content;\n\tposition: relative;\n\tmargin-right: 20px;\n\n\tbutton {\n\t\tposition: absolute;\n\t\ttop: -15px;\n\t\tright: -15px;\n\t\tz-index: 1000;\n\t}\n}\n\n.dive-image {\n\tborder-radius: 5px;\n\theight: 200px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
