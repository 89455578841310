// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  height: 100%;
}

.bg {
  /* The color used 
  background-color:#ACD4E2  ; */
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/about-page/about-page.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE;+BAAA;EAEA,gBAAA;EACA,YAAA;EACA,sCAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA;AACF","sourcesContent":["body, html {\n  height: 100%;\n}\n\n.bg {\n  /* The color used \n  background-color:#ACD4E2  ; */\n  /* Full height */\n  height: 100%;\n  /* Center and scale the image nicely */\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
