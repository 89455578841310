import { ComponentRef, Directive, Host, HostBinding, Input, OnDestroy, OnInit, Optional, ViewContainerRef } from '@angular/core';
import { WebApiService } from '../services/web-api.service';
import { Subscription, debounceTime, filter } from 'rxjs';
import { LoadingScreenComponent } from '../components/loading-screen/loading-screen.component';
import { LoadingHost } from '../models/loading-host.model';

@Directive({
	selector: '[appHasLoadingScreen]'
})
export class HasLoadingScreen implements OnInit, OnDestroy {
	// to host component, add:
	// providers: [{ provide: LoadingHost, useExisting: <host component> }]
	// and add 'this' argument to every GET request to be affected by loading screen
	constructor(
		@Optional() @Host() private host: LoadingHost,
		private webApi: WebApiService,
		private viewContainerRef: ViewContainerRef) { }

	private hostId = this.host ? Object.entries(this.host).find(entry => entry.includes("__ngContext__"))[1] : null
	private sub: Subscription;
	private loadingScreen: ComponentRef<LoadingScreenComponent>;

	@Input() lsShape: string = "bar"
	@Input() lsHeight: string = "auto"
	@HostBinding('hidden') hidden: boolean = true;

	ngOnInit(): void {
		this.loadingScreen = this.viewContainerRef.createComponent(LoadingScreenComponent)
		this.loadingScreen.instance.shape = this.lsShape
		this.loadingScreen.instance.height = this.lsHeight
		this.sub = this.webApi.loadingReport$.pipe(filter(l => l?.length > 0), debounceTime(100)).subscribe(loadingLedger => {
			let matchingItems = loadingLedger.filter(item => item.id == this.hostId);
			for (let item of matchingItems) {
				if (item.error && !item.silentFail) {
					this.loadingScreen.instance.error = item.error;
					break
				}
				if (item.isDone || (item.error && item.silentFail)) {
					this.hidden = false;
					this.loadingScreen?.destroy();
				}
			}
		})
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

}
