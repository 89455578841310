// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-mdc-card-outlined {
  border-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/app/admin-module/pages/admin-page/admin-page.component.scss"],"names":[],"mappings":"AAAA;EACC,oCAAA;AACD","sourcesContent":[".mat-mdc-card-outlined {\n\tborder-color: transparent !important; \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
