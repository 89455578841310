import { Component, OnInit } from '@angular/core';
import { Dive } from '../../home-module/models/dive.model';
import { DivesService } from 'src/app/home-module/services/dives.service';
import { LoginService } from 'src/app/shared-module/services/login.service';
import { LoadingHost } from 'src/app/shared-module/models/loading-host.model';

@Component({
	selector: 'app-last-dive',
	templateUrl: './last-dive.component.html',
	styleUrls: ['./last-dive.component.scss'],
	providers: [{ provide: LoadingHost, useExisting: LastDiveComponent }]
})
export class LastDiveComponent implements OnInit {

	constructor(private divesService: DivesService, private loginService: LoginService) { }

	public dive: Dive;

	ngOnInit(): void {
		this.divesService.getDive("-1", this).then(d => this.dive = new Dive(d))
	}

}
