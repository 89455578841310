import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { WebApiService } from '../../services/web-api.service';
import { ImageItem } from 'src/app/home-module/models/image-item.model';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

	@Input() requiredFileType: string = ".thm, .avif, .heic, .heif, .jls, .jp2, .jpeg, .jpg, .jpx, .jxr, .png, .tiff, .webp"
	@Input() withTitle: boolean = true
	@Input() purpose: string = null
	@Output() uploadDone = new EventEmitter<ImageItem>()

	public fileName = '';
	public uploadProgress: number;
	public uploadSub: Subscription;
	public error: string = null
	public title = ''

	constructor(private webApi: WebApiService) { }

	onFileSelected(event) {
		this.error = null
		const file: File = event.target.files[0];
		if (file) {
			this.fileName = file.name;
			const formData = new FormData();
			formData.append("file", file);
			formData.append("title", this.title);
			this.webApi.uploadFile('images', formData).pipe(finalize(() => this.reset())).subscribe(
				{
					next: event => {
						if (event.type == HttpEventType.UploadProgress) {
							this.uploadProgress = Math.round(100 * (event.loaded / event.total));
						};
						if (event.type == HttpEventType.Response) {
							this.uploadComplete(event)
						};
					},
					error: err => { this.error = err.message },
				})
		}
	}

	uploadComplete(response) {
		this.uploadDone.emit(new ImageItem(response.body))
		this.reset();
	}

	cancelUpload() {
		this.uploadSub.unsubscribe();
		this.reset();
	}

	reset() {
		this.title = ''
		this.fileName = ''
		this.uploadProgress = null;
		this.uploadSub = null;
	}
}
