import { Component } from '@angular/core';

@Component({
	selector: 'app-notifications-handling-page',
	templateUrl: './notifications-page.component.html',
	styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent {
	constructor() { }
}
