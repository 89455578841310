import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { Contact } from "./contact.model";
import { Moment } from "moment";
import * as moment from "moment";
import { ImageItem } from "./image-item.model";
import { User } from "src/app/shared-module/models/user.model";

export class Dive extends CanMarshal<Dive> {

	constructor(propsObj?: Dive) {
		super(propsObj);
		this.startTimestamp = propsObj?.startTimestamp ? moment(propsObj.startTimestamp) : moment()
		this.endTimestamp = propsObj?.endTimestamp ? moment(propsObj.endTimestamp) : moment()
		this.buddies = propsObj?.buddies?.map((b: Contact) => new Contact(b)) || [];
		if (propsObj?.userObject) this.userObject = new User(propsObj.userObject);
		if (propsObj?.images) this.images = propsObj.images.map((i: ImageItem) => new ImageItem(i));
	}

	public uuid: string
	public diveNo: number
	public title?: string
	public description?: string
	public startTimestamp: Moment
	public endTimestamp: Moment
	public tzoffset: number
	public visibility?: "bad" | "murky" | "poor" | "fair" | "good" | "excellent"
	public current?: "none" | "light" | "medium" | "strong"
	public waterTemp?: number // in celsius
	public maxDepth: number // in meters
	public mixture: number // in percentage, default 21
	public bottomTime: number // in minutes
	public cylinder?: "steel" | "aluminum"
	public cylinderVolume?: 7 | 10 | 12 | 15 // in litres
	public startPressure: number // in bars
	public endPressure: number // in bars
	public diveType?: ("fresh" | "salt" | "shore" | "drift" | "night" | "boat" | "training" | "propulsion" | "cave" | "altitude" | "course")[]
	public weights: number // in pounds
	public equipmentUsed: string[] // uuid array; or object with keys of every equipment type?
	public site: { name: string, uuid: string } // todo
	public resort: { name: string, uuid: string } // todo - add verification and stuff
	public buddies: Contact[]
	public images?: ImageItem[] // todo
	public user?: string
	public userObject?: User
}
