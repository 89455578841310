import { Injectable } from "@angular/core"
import { WebApiService } from "src/app/shared-module/services/web-api.service"
import { UserAffiliation } from "../models/user-affiliation.model"
import { Certification } from "../models/certification.model"
import { LoginService } from "src/app/shared-module/services/login.service"


@Injectable({
	providedIn: 'root',
})
export class CertificationsService {

	constructor(
		private webApi: WebApiService,
		private loginService: LoginService) { }

	private get user() {
		return this.loginService.viewedUser.uuid
	}

	public listUserAffiliations(caller?: any): Promise<UserAffiliation[]> {
		return this.webApi.getList(`users/${this.user}/affiliations`, UserAffiliation, caller)
	}

	public getCertification(uuid: string, caller?: any): Promise<Certification> {
		return this.webApi.getOne(`users/${this.user}/affiliations/${uuid}`, Certification, caller)
	}

	public createCertification(
		association: string,
		certificate: string,
		data: Partial<Certification>,
		caller?: any): Promise<Certification> {
		return this.webApi.create(`users/${this.user}/affiliations/${association}/${certificate}`, Certification, data, caller)
	}

	public updateCertification(uuid: string, data: Partial<Certification>, caller?: any): Promise<Certification> {
		return this.webApi.update(`users/${this.user}/affiliations/${uuid}`, Certification, data, caller)
	}

	public deleteCertification(uuid: string, caller?: any) {
		return this.webApi.delete(`users/${this.user}/affiliations/${uuid}`, caller)
	}
}
