import { Component, OnInit } from "@angular/core";
import { User } from "src/app/shared-module/models/user.model";
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Subscription, filter } from "rxjs";

@Component({
	selector: 'app-admin-page',
	templateUrl: './admin-page.component.html',
	styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements OnInit {

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) { }

	public links = ['users', 'resorts'];
	public activeLink: string = this.links[0]
	private sub: Subscription;

	ngOnInit(): void {
		this.resolveLink();
		this.sub = this.router.events.pipe(filter(e => e instanceof NavigationEnd))
			.subscribe(e => this.resolveLink())
	}

	private resolveLink() {
		let url = this.activatedRoute.firstChild?.snapshot.url[0].path;
		if (!url) {
			this.router.navigateByUrl('/admin/users')
		} else if (url != this.activeLink) { this.activeLink = url };
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

}
