import { Injectable } from '@angular/core';
import { WebApiService } from './web-api.service';
import { SwPush } from '@angular/service-worker';
import { BehaviorSubject, tap } from 'rxjs';
import { PlaceHolder } from '../models/place-holder.model';
import { AppBaseNotification } from '../models/app-base-notification.model';
import { LoginService } from './login.service';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {

	constructor(
		private webApi: WebApiService,
		private swPush: SwPush,
		private loginService: LoginService) { }

	private get user() {
		return this.loginService.viewedUser.uuid
	}

	public notificationsInterval = null

	public subscribeToNotifications(user: string) {
		this.webApi.excludeFromLoading(`users/${user}/notifications/register`)
		this.webApi.getOne(`users/${user}/notifications/register`).then(res => {
			this.swPush.requestSubscription({
				serverPublicKey: res.publicKey
			})
				.then(sub => { console.log(sub); this.webApi.create(`users/${user}/notifications/register`, null, sub) })
				.catch(err => console.error("Could not subscribe to notifications", err));
		})
	}

	public initAppNotifications() {
		this.listNotifications()
			.finally(() => {
				this.notificationsInterval = setInterval(() => {
					this.listNotifications()
				}, 30000)
			})
	}

	public listNotifications(): Promise<AppBaseNotification[]> {
		this.webApi.excludeFromLoading(`users/${this.user}/notifications`)
		return this.webApi.getList(`users/${this.user}/notifications`, AppBaseNotification).then((res: AppBaseNotification[]) => {
			this.AppNotificationsSubject$.next(res);
			return res
		})
	}

	public getNotification(uuid: string, caller?: any): Promise<AppBaseNotification> {
		return this.webApi.getOne(`users/${this.user}/notifications/${uuid}`, AppBaseNotification, caller)
	}

	public completeNotification(notificationUUID: string, data?: any, caller?: any): Promise<any> {
		let currentList = this.AppNotificationsSubject$.getValue() || []
		let nextList = currentList.filter(notf => notf.uuid != notificationUUID)
		this.AppNotificationsSubject$.next(nextList)
		return this.webApi.update(`users/${this.user}/notifications/${notificationUUID}`, AppBaseNotification, { completion: data }, caller)
	}

	public viewNotification(notificationUUID: string, caller?: any): Promise<any> {
		return this.webApi.update(`users/${this.user}/notifications/${notificationUUID}`, AppBaseNotification, { viewed: true }, caller)
	}

	public pushNotifications$ = this.swPush.messages.pipe(tap(notification => console.log("new push notification", notification)))
	public notificationClicks$ = this.swPush.notificationClicks.pipe(tap(notification => {
		console.log("notification click", notification);
	}))

	private AppNotificationsSubject$ = new BehaviorSubject<AppBaseNotification[]>(null)
	public get AppNotifications$() {
		return this.AppNotificationsSubject$.asObservable()
	}

	// for testing
	public createNotification(content: PlaceHolder, caller?: any) {
		return this.webApi.create(`users/${this.user}/notifications/test`, PlaceHolder, content, caller)
	}

	// for testing
	public pushNotification(content: PlaceHolder, caller?: any) {
		return this.webApi.create(`users/${this.user}/notifications/rawtest`, PlaceHolder, content, caller)
	}

}
