import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { RoleType } from "./role-type.model";

export class Role extends CanMarshal<Role> {

	public role: number
	public type: RoleType
	public resort?: { name: string, uuid: string }
	public association?: { name: string, uuid: string }
	public approvedBy?: string
	public approvedAt?: string

	public get icon(): string {
		switch (this.type) {
			case "systemManager": return 'shield_person';
			case "associationManager": return 'person_celebrate';
			case "resortManager": return 'person_play';
			case "resortRep": return 'support_agent';
			case "resortInstructor": return 'record_voice_over';
			case "diver": return 'scuba_diving';
		}
	}

	public get name(): string {
		switch (this.type) {
			case "systemManager": return 'System Manager';
			case "associationManager": return 'Association Manager';
			case "resortManager": return 'Resort Manager';
			case "resortRep": return 'Resort Rep';
			case "resortInstructor": return 'Resort Instructor';
			case "diver": return 'Diver';
		}
	}

}

