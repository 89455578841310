import { ComponentRef, Directive, ElementRef, Host, HostBinding, Input, OnDestroy, OnInit, Optional, ViewContainerRef } from '@angular/core';
import { WebApiService } from '../services/web-api.service';
import { Subscription, filter } from 'rxjs';
import { LoadingHost } from '../models/loading-host.model';
import { ProgressScreenComponent } from '../components/progress-screen/progress-screen.component';
import { Router } from '@angular/router';

@Directive({
	selector: '[appHasProgressScreen]'
})
export class HasProgressScreenDirective implements OnInit, OnDestroy {
	// host component HAVE to be inside a positioned ancestor!
	// to host component, add:
	// providers: [{ provide: LoadingHost, useExisting: <host component> }]
	// and add 'this' argument to every POST/PATCH/DELETE request to be affected by progress screen
	constructor(
		@Optional() @Host() private host: LoadingHost,
		private webApi: WebApiService,
		private viewContainerRef: ViewContainerRef,
		private elementRef: ElementRef,
		private router: Router) { }

	private hostId = this.host ? Object.entries(this.host).find(entry => entry.includes("__ngContext__"))[1] : null
	private sub: Subscription;
	private progressScreen: ComponentRef<ProgressScreenComponent>;

	@Input() psShape: string = "spinner"
	@Input() navigateAfter: string = null
	@HostBinding('style.opacity') opacity = '1';

	ngOnInit(): void {
		this.sub = this.webApi.progressReport$.pipe(filter(l => l?.length > 0)).subscribe(progressLedger => {
			let matchingItems = progressLedger.filter(item => item.id == this.hostId);
			for (let item of matchingItems) {
				if (item.error) {
					this.opacity = '0.0'
					this.progressScreen.instance.error = item.error;
					this.progressScreen.instance.tryAgain.subscribe(() => { this.progressScreen.destroy(); this.opacity = '1' })
					break
				}
				if (!item.isDone) {
					this.opacity = '0.3'
					this.progressScreen = this.viewContainerRef.createComponent(ProgressScreenComponent)
					this.progressScreen.instance.shape = this.psShape
					this.progressScreen.location.nativeElement.style.height = this.elementRef.nativeElement.offsetHeight + "px"
					this.progressScreen.location.nativeElement.style.width = this.elementRef.nativeElement.offsetWidth + "px"
					break
				}
				if (item.isDone) {
					this.opacity = '1'
					this.progressScreen?.destroy();
					if (this.navigateAfter) { this.router.navigateByUrl(this.navigateAfter) }
				}
			}
		})
	}

	ngOnDestroy(): void {
		this.sub.unsubscribe()
	}

}
