import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";
import { Association } from "./association.model";

import { Certification } from "./certification.model";

export class UserAffiliation extends CanMarshal<UserAffiliation> {
	constructor(propsObj?: UserAffiliation) {
		super(propsObj);
		this.certifications = propsObj.certifications?.map((cer: Certification) => new Certification(cer));
		this.association = new Association(propsObj.association)
	}

	public association: Association;
	public certifications: Certification[];
}