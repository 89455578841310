import { Component, OnInit } from "@angular/core";
import { Dive } from "src/app/home-module/models/dive.model";
import { DivesService } from "src/app/home-module/services/dives.service";
import { User } from "src/app/shared-module/models/user.model";
import { LoginService } from "src/app/shared-module/services/login.service";


@Component({
	selector: 'app-main-page',
	templateUrl: './main-page.component.html',
	styleUrls: ['./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

	constructor(private loginService: LoginService) { }
	public currentUser: User

	ngOnInit() {
		this.currentUser = this.loginService.currentUser
	}

}
