import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dive } from 'src/app/home-module/models/dive.model';
import { ApproveDiveNotification } from 'src/app/shared-module/models/approve-dive-notification.model';
import { LoadingHost } from 'src/app/shared-module/models/loading-host.model';
import { User } from 'src/app/shared-module/models/user.model';
import { LoginService } from 'src/app/shared-module/services/login.service';
import { NotificationsService } from 'src/app/shared-module/services/notifications.service';

@Component({
	selector: 'app-approve-dive-handler',
	templateUrl: './approve-dive-handler.component.html',
	styleUrls: ['./approve-dive-handler.component.scss'],
	providers: [{ provide: LoadingHost, useExisting: ApproveDiveHandlerComponent }]
})
export class ApproveDiveHandlerComponent implements OnChanges {

	constructor(
		private notificationsService: NotificationsService,
		private router: Router,
		private loginService: LoginService
	) { }

	@Input() notification: ApproveDiveNotification;
	public dive: Dive;
	public diveUser: User;
	private user = this.loginService.currentUser


	ngOnChanges(): void {
		this.dive = new Dive(this.notification.content.dive)
		this.diveUser = new User(this.notification.content.user)
	}

	public onIgnore() {
		this.notificationsService.completeNotification(this.notification.uuid, { rejected: 'ignore' }, this)
	}


}
