import { NgModule } from '@angular/core';
import { LoginErrorMessageDialog } from './components/login-error-message-dialog/login-error-message-dialog';
import { LibrariesModule } from '../libraries-module/libraries.module';
import { CommonModule } from '@angular/common';
import { HasLoadingScreen } from './directives/has-loading-screen.directive';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { HasProgressScreenDirective } from './directives/has-progress-screen.directive';
import { TempUnitsPipe } from './pipes/temp-units.pipe';
import { DepthUnitsPipe } from './pipes/depth-units.pipe';
import { ProgressScreenComponent } from './components/progress-screen/progress-screen.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { QRCodeModule } from 'angularx-qrcode';
import { InlineSpinnerComponent } from './components/inline-spinner/inline-spinner.component';
import { ImagesFormComponent } from './components/images-form/images-form.component';
import { DayNamePipe } from './pipes/day-name.pipe';


@NgModule({
	declarations: [
		LoginErrorMessageDialog,
		HasLoadingScreen,
		LoadingScreenComponent,
		HasProgressScreenDirective,
		TempUnitsPipe,
		DepthUnitsPipe,
		ProgressScreenComponent,
		FileUploadComponent,
		InlineSpinnerComponent,
		ImagesFormComponent,
  DayNamePipe
	],
	imports: [
		CommonModule,
		LibrariesModule
	],
	exports: [
		HasLoadingScreen,
		LoadingScreenComponent,
		HasProgressScreenDirective,
		TempUnitsPipe,
		DepthUnitsPipe,
		FileUploadComponent,
		QRCodeModule,
		InlineSpinnerComponent,
		ImagesFormComponent,
		DayNamePipe
	],
	providers: []
})
export class SharedModule { }
