import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, filter, tap } from 'rxjs';
import { WebApiService } from '../services/web-api.service';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {

	constructor(private webApi: WebApiService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this.webApi.onRequest(request)
		return next.handle(request)
			.pipe(tap({
				next: response => {
						if (response instanceof HttpResponse) {
						this.webApi.onResponse(response)
					};
				},
				error: err => {
					if (err instanceof HttpErrorResponse) {
						this.webApi.onError(err)
					};
				}
			})
			);
	};
}
