import * as moment from "moment";
import { Moment } from "moment";
import { CanMarshal } from "src/app/shared-module/models/canMarshal.model";

export class DiveValidation extends CanMarshal<DiveValidation> {
	constructor(propsObj?: DiveValidation) {
		super(propsObj)
		this.startTimestamp = propsObj.startTimestamp ? moment(propsObj.startTimestamp) : null
		this.endTimestamp = propsObj.endTimestamp ? moment(propsObj.endTimestamp) : null
	}
	public diveNo: number
	public startTimestamp?: Moment
	public endTimestamp?: Moment
}