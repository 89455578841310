import { Component } from "@angular/core"
import { CertificationsService } from "src/app/home-module/services/certifications.service"
import { EquipmentService } from "src/app/home-module/services/equipment.service"
import { UserService } from "src/app/shared-module/services/users.service"
import { ConfigurationService } from "../../shared-module/services/configuration.service"
import { WebApiService } from "../../shared-module/services/web-api.service"
import { LoginService } from "src/app/shared-module/services/login.service"
import { HttpClient, HttpParams } from "@angular/common/http"
import { lastValueFrom } from "rxjs"
import { EquipmentItem } from "src/app/home-module/models/equipment-item.model"
import { CalendarDate } from "calendar-date"
import * as moment from "moment"
import { Moment } from "moment"
import { Contact } from "src/app/home-module/models/contact.model"
import { NotificationsService } from "src/app/shared-module/services/notifications.service"

@Component({
	selector: 'app-dev',
	templateUrl: './dev.component.html',
	styleUrls: ['./dev.component.scss']
})
export class DevComponent {

	constructor(
		private webApi: WebApiService,
		private config: ConfigurationService,
		private usersService: UserService,
		private certService: CertificationsService,
		private eqService: EquipmentService,
		private loginService: LoginService,
		private eqSerivce: EquipmentService,
		private http: HttpClient,
		private notificationsService: NotificationsService) { }


	async test() {
		let user = this.loginService.currentUser.uuid
		console.warn("TESTS")

		let content =
		{
			notification:
			{
				title: "New Notification!",
				body: "this is a very nice notification",
				icon: "https://i.natgeofe.com/n/4f5aaece-3300-41a4-b2a8-ed2708a0a27c/domestic-dog_thumb_square.jpg",
				requireInteraction: true,
				"data": {
					"onActionClick": {
						default: { operation: "navigateLastFocusedOrOpen", url: "/notifications?type=some&uuid=1234-6789" },
					}
				}
			}
		}

		this.notificationsService.pushNotification(user, content)
	}

	async add() {
		let user = this.loginService.currentUser.uuid
		console.warn("ADD")
		this.notificationsService.createNotification(user,
			"Test notification"
		)
	}




}
